import { getShopData, getShopDomain } from 'utils/firebase';

const Index = () => {
  return <div></div>;
};

export async function getServerSideProps({ req, res }) {
  res.setHeader('Cache-Control', 'public, s-maxage=1, stale-while-revalidate=300');

  try {
    const { redirect } = await getShopData(getShopDomain(req));
    if (redirect) {
      return { redirect: { permanent: false, destination: redirect } };
    }
    return { props: {} };
  } catch (e) {
    if (e.status === 404) {
      return { redirect: { permanent: false, destination: '/404' } };
    }
    throw e;
  }
}

export default Index;
